<template>
<!--<div class="top-bar">-->
<!--    <nav class="nav">-->
<!--      <ul class="nav-block">-->
<!--        <li class="nav-list">-->
<!--          <a-->
<!--              href="https://cemglobal.com/"-->
<!--              class="nav-link">-->
<!--              Our Company-->
<!--          </a>-->
<!--        </li>-->
<!--        <li-->
<!--            class="nav-list"-->
<!--            v-for="(navItem, idx) in topBarData"-->
<!--            :key="idx"-->
<!--        >-->
<!--          <a-->
<!--              :href="navItem.link"-->
<!--              class="nav-link"-->
<!--          >-->
<!--            <img-->
<!--                :src="navItem.logo"-->
<!--                alt="logo">-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
<!--    <nav class="nav-mobile">-->
<!--      <div class="nav-mobile-top">-->
<!--        <p @click="ShowNavMobile">-->
<!--          Our Company-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
<!--            <path fill-rule="evenodd" d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>-->
<!--          </svg>-->
<!--        </p>-->
<!--        <a href="https://cemglobal.com/" class="nav-mobile-top-link">-->
<!--          <img src="https://continental.one/img/white-logo/sem-logo.jpg" style='width: 176px;' alt="logo">-->
<!--        </a>-->
<!--      </div>-->
<!--      <ul-->
<!--          class="nav-mobile-block"-->
<!--          v-if="topBarShow"-->
<!--      >-->
<!--        <li-->
<!--            class="nav-mobile-block-list"-->
<!--            v-for="(navItem, idx) in topBarData"-->
<!--            :key="idx"-->
<!--        >-->
<!--          <a :href="navItem.link" class="nav-mobile-block-link">-->
<!--            {{navItem.name}}-->
<!--            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                    d="M11 13H1C0.4 13 0 12.6 0 12V2C0 1.4 0.4 1 1 1H6V2H1V12H11V7H12V12C12 12.6 11.6 13 11 13ZM8 0V1H11.3L7 5.3L7.7 6L12 1.7V5H13V0H8Z"-->
<!--                    fill="white"></path>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
<!--  </div>-->
    <!-- partners block -->
    <div>
        <div class="partners-block" style="background-color: black; padding: 0 80px; justify-content: space-between; align-items: center">
            <div style="display: flex; justify-content: center; gap: 16px; align-items: center; padding-top: 8px; padding-bottom: 8px;">
                <a href="https://cemglobal.com/" class="cemglobal" style="color: white; width: 118px; padding-right: 16px; border-right: 2px solid white; display: flex; justify-content: center; align-items: center">
                    <img src="@/assets/images/partners/logo-cemglobal.png" height="32" alt="cemglobal">
                </a>
            </div>

            <a href="https://www.electroluxcollection.com/" style="padding: 24px 12px; color: white; display: inline-flex; align-items: center; justify-content: center">
                <img src="@/assets/images/partners/logo-electrolux-white.svg" alt="Electrolux" style="width: 96px;">
            </a>

            <a href="https://cemglobalfw.com/" style="padding: 24px 12px; color: white; display: inline-flex; align-items: center; justify-content: center">
                <img src="@/assets/images/partners/logo-farberware-white.svg" alt="Farberware" style="width: 96px;">
            </a>

            <a href="https://Continental.one" style="color: white; display: inline-flex; align-items: center; padding: 16px 12px; justify-content: center">
                <img src="@/assets/images/partners/logo-continental-white.svg" style="width: 128px;" alt="Continental">
            </a>

            <a href="https://collezioni.com/" style="padding: 24px 12px; color: white; display: inline-flex; align-items: center; justify-content: center">
                <img src="@/assets/images/partners/logo-collezioni.png" style="width: 96px;" alt="Collezioni">
            </a>

            <a href="https://ProSeries.one" style="color: white; display: inline-flex; align-items: center; padding: 16px 12px; justify-content: center">
                <img src="@/assets/images/partners/logo-proseries-white.svg" style="width: 80px;" alt="Professional Series">
            </a>

            <a href="https://ceconsumer.com/" style="color: white; display: inline-flex; align-items: center; padding: 16px 10px; justify-content: center">
                <img src="@/assets/images/partners/logo-consumer.png" style="width: 88px;" alt="Consumer Electronics">
            </a>

            <a href="http://americanoutdoors.life/" style="padding: 24px 12px; align-self: stretch; display: inline-flex; align-items: center; justify-content: center">
                <img src="@/assets/images/partners/logo-american-outdoors.png" style="width: 80px;" alt="American Outdoors">
            </a>

            <a href="https://GymMaster.Fitness" style="color: white; display: inline-flex; align-items: center; padding: 16px 12px; justify-content: center">
                <img src="@/assets/images/partners/logo-gymmaster-white.svg" style="width: 112px;" alt="Gym Master">
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: "TopBar",
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";

.partners-block {
    display: flex;

    img {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .partners-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 20px !important;

        a {
            padding: 8px 12px !important;
        }

        a.cemglobal {
            border: none !important;
        }
    }
}

@media (max-width: 525px) {
    .partners-block {
        padding: 0 10px !important;

        a {
            padding: 4px 8px !important;
        }

        a.cemglobal {
            border: none !important;
        }
    }
}
</style>
