<template>
<header class="header-nav">
  <div class="container">
    <div class="header-left">
      <div class="header-left-logo">
        <router-link :to="{path: '/'}" class="logo-text">
          <img src="../../assets/images/ce-logo.png" alt="">
        </router-link>
      </div>
      <nav class="nav">
        <ul class="nav-block">
          <li
              class="nav-list"
              v-for="(category, idx) in categories"
              :key="idx"
              @mouseover="showSubNav(category, idx)"
              @mouseleave="hideSubNav"
              @click="getPageId(category.id)"
          >
          
            <router-link class="nav-button" tag="button" :to="{path: `/categories/${category.id}`}">
              <span style="width: 100%; height: 100%">
                {{ category.name }}
              </span>
            </router-link>
            <SubNav
                v-if="activeSubNav === idx"
                :subNav="subNav"
                :link="category.link"
            />
          </li>
          <li class="nav-list mob-item">
            <router-link
                :to="{path: '/about'}"
                class="nav-button">
              About
            </router-link>
          </li>
          <li class="nav-list mob-item">
            <router-link
                :to="{path: '/support'}"
                class="nav-button">
              Support
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="header-right">
      <nav class="nav">
        <ul class="nav-block">
          <li class="nav-list">
            <router-link
                :to="{path: '/about'}"
                class="nav-button">
              About
            </router-link>
          </li>
          <li class="nav-list">
            <router-link
                :to="{path: '/support'}"
                class="nav-button">
              Support
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
</template>

<script>

import SubNav from "@/components/Nav/SubNav";
import {mapActions} from "vuex/dist/vuex.esm.browser";

export default {
  name: "Header",
  components: {SubNav},
  data () {
    return{
      subNav: [],
      activeSubNav: null,
      categories: []
    }
  },
  methods: {
    ...mapActions(['categoryIdRemove']),
    showSubNav (category, idx) {
      this.activeSubNav = idx
      this.subNav = category.children
    },
    hideSubNav (){
      this.subNav = []
      this.activeSubNav = null
    },
    getPageId (id) {
      this.categoryIdRemove(id)
    }
  },
  async created() {
    this.categories = (await this.axios.get('/categories')).data.reverse()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vars.scss";
.header-nav{
  width: 100%;
  position: relative;
  z-index: 99;
  background-color: $blackBg;
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mob-item {
        display: none;
        align-self: center;
    }
    .header-left{
      max-width: 750px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 43px;
      .header-left-logo{
        max-width: 130px;
        width: 100%;
          img {
            width: 100%;
          }
      }
      .nav{
        max-width: 585px;
        width: 100%;
        .nav-block{
          display: flex;
          justify-content: space-between;
          .nav-list{
            .nav-button{
              padding: 21px 15px;
              span{
                //padding: 21px 0;
              }
            }
          }
        }
      }
    }
    .header-right{
      max-width: 440px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 40px;
      .nav{
        max-width: 131px;
        width: 100%;
        .nav-block{
          display: flex;
          justify-content: space-between;
        }
      }
      .search-input{
        margin: 14px 0;
      }
    }
  }

  @media (max-width: 1400px) {
    .container{
      .header-left{
        margin-left: 10px;
        max-width: 725px;
      }
      .header-right{
        max-width: 370px;
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1200px) {
    .container{
      flex-wrap: wrap;
      .header-left{
        max-width: 100%;
        .header-left-logo{
          display: none;
        }
        .nav{
          margin: 0 auto;
        }
      }
      .header-right{
        display: block;
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 576px) {
    .container{
      flex-wrap: wrap;
      .header-left{
        .nav{
          .nav-block{
            flex-wrap: wrap;
          }
        }
      }
      .mob-item {
        display: block;
      }
      .header-right{
        display: none;
        margin-left: 25px;
        max-width: 310px;
        .nav{
          max-width: 105px;
        }
        .search-input{
          max-width: 160px;
        }
      }
    }
  }
}
</style>
