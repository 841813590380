<template>
  <div
      class="header-section"
      :style="bgImg"
  >
    <div
        class="container"
    >
      <div class="header">
        <h1 class="title"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="500"
        >Welcome to the Family</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSection",
  data () {
    return{
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/header-bg.png')})`
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.header-section{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .container{
    position: relative;
    z-index: 9;
    padding: 504px 0 180px;
    color: $white;
    .title{
      margin-left: 40px;
    }
    .text-small{
      max-width: 552px;
      width: 100%;
      margin-left: 40px;
    }
  }
}
@media (min-width: 1500px) {
  .header-section{
    .container{
      padding: 500px 0 108px;
      .title{
        //margin-top: 80px;
      }
    }
  }
}
@media (min-width: 2400px) {
  .header-section{
    .container{
      padding: 600px 0 108px;
      .title{
        margin-top: 80px;
      }
    }
  }
}
@media (min-width: 3000px) {
  .header-section{
    .container{
      padding: 700px 0 108px;
      .title{
        margin-top: 80px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .header-section{
    .container{
      padding: 335px 0 40px;
    }
  }
}
@media (max-width: 576px) {
  .header-section{
    .container{
      padding: 130px 0 30px;
      .header{
        .title{
          font-size: 23px;
        }
      }
    }
  }
}
</style>
