<template>
  <div class="home-support">
    <div class="container">
      <h2 class="title support-center-title">Support Center</h2>
      <div class="home-support-block">
        <div class="home-support-block-item">
          <router-link :to="{path: '/contact'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_42" data-name="Layer 42">
                  <rect class="cls-1" width="128" height="128"/>
                  <path
                      d="M94.23,42H93V87.09A4.91,4.91,0,0,1,88.09,92H46.77l-5.31,6H94.23A4.76,4.76,0,0,0,99,93.23V46.77A4.76,4.76,0,0,0,94.23,42Z"/>
                  <path
                      d="M86.09,34H29.91A4.91,4.91,0,0,0,25,38.91V90H35l0,11,9.77-11H86.09A4.91,4.91,0,0,0,91,85.09V38.91A4.91,4.91,0,0,0,86.09,34ZM43.75,79a1.6,1.6,0,0,1-1.57-1.25l-1.61-7a1.62,1.62,0,0,1,.94-1.85L49,65.72a1.61,1.61,0,0,1,1.88.47l3.32,4.06A24.84,24.84,0,0,0,66.09,58.37L62,55.05a1.6,1.6,0,0,1-.46-1.88l3.22-7.51a1.61,1.61,0,0,1,1.84-.93l7,1.61a1.61,1.61,0,0,1,1.25,1.57A31.1,31.1,0,0,1,43.75,79Z"/>
                </g>
              </g>
            </svg>
            <p class="support-item-text">
                Contact
              </p>
          </router-link>
        </div>
        <div class="home-support-block-item">
          <router-link :to="{path: 'return-policy'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" class="registration-icon">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_43" data-name="Layer 43">
                  <rect class="cls-1" width="128" height="128"/>
                  <path class="cls-2"
                        d="M28.72,79.66A38.6,38.6,0,0,1,61.29,25.5a39,39,0,0,1,14.16,1.64,38.28,38.28,0,0,1,15,9.12V26.5"/>
                  <path class="cls-2" d="M99.2,48.31A38.6,38.6,0,0,1,52.55,101a38.2,38.2,0,0,1-15-9.11v9.61"/>
                  <path
                      d="M67,65.23l10-6.29V69.37L67,75.66ZM51,69.37l10,6.29V65.23L51,58.94ZM95.34,64A31.34,31.34,0,1,1,64,32.64,31.35,31.35,0,0,1,95.34,64ZM79,54,63.5,46.09,48,54V70.8l15.5,9.74L79,70.8Zm-25.83.14,10.83,7,10.83-7L64,48.61Z"/>
                </g>
              </g>
            </svg>
            <p class="support-item-text">
              Return Policy
            </p>
          </router-link>
        </div>
        <div class="home-support-block-item">
          <router-link :to="{path: 'warranty-info'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_44" data-name="Layer 44">
                  <rect class="cls-1" width="128" height="128"/>
                  <path class="cls-2"
                        d="M89.93,35.37a45.86,45.86,0,0,1-15.28-6.58L64,21.66,53.35,28.79a45.81,45.81,0,0,1-15.27,6.58L31,37V66.87A31.59,31.59,0,0,0,45.51,93.45L64,105.34,82.49,93.45A31.59,31.59,0,0,0,97,66.87V37ZM81,90.3,64.5,100.9,48,90.3A28.32,28.32,0,0,1,35,66.47V39.79l6.3-1.43A41.14,41.14,0,0,0,55,32.45l9.5-6.35L74,32.46a41.33,41.33,0,0,0,13.7,5.9L94,39.79V66.47A28.32,28.32,0,0,1,81,90.3Zm-8.1-56.2L64.5,28.49,56.12,34.1a43.46,43.46,0,0,1-14.46,6.23L37,41.39V66.65A26,26,0,0,0,49,88.56l15.53,10L80,88.56A26.06,26.06,0,0,0,92,66.65V41.39l-4.66-1.06A43.41,43.41,0,0,1,72.89,34.1Zm8.67,22.45L65.74,72.79a2.26,2.26,0,0,1-1.63.69,2.3,2.3,0,0,1-1.53-.59L52.23,63.48a2.28,2.28,0,0,1,3.06-3.37L64,68,78.3,53.38a2.27,2.27,0,0,1,3.26,3.17Z"/>
                </g>
              </g>
            </svg>
            <p class="support-item-text">
              Warianty Info
            </p>
          </router-link>
        </div>
        <div class="home-support-block-item">
          <router-link :to="{path: '/registration'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_45" data-name="Layer 45">
                  <rect class="cls-1" width="128" height="128"/>
                  <path class="cls-2"
                        d="M84.67,28.32l-2.33,2.33H45.51a4.67,4.67,0,0,0-4.66,4.67V47H38.51V33a4.67,4.67,0,0,1,4.67-4.67Zm7.51,56-7,7-7,7H45.51a4.66,4.66,0,0,1-4.66-4.66V76.15H38.51V96a4.67,4.67,0,0,0,4.67,4.67H79.24l7.64-7.49,7.64-7.48V39.5l-2.34,2.33Zm-17.46-25-.38.16-9.88,4a3.27,3.27,0,0,1-1.27.25A3.38,3.38,0,0,1,60.07,59l4-9.88.15-.38.29-.29L80,33H45.51a2.33,2.33,0,0,0-2.33,2.33V47h3.5a3.5,3.5,0,0,1,0,7h-3.5V69.15h3.5a3.5,3.5,0,1,1,0,7h-3.5V93.66A2.34,2.34,0,0,0,45.51,96H77V88.21a5.07,5.07,0,0,1,5.06-5.06h7.78v-39L75,59Zm-12.8.5a1.38,1.38,0,0,0,1.79,1.79l9.08-3.67-7.2-7.2Zm5.08-11,7.68,7.68L96.36,34.82l-7.68-7.68Zm35.89-24.29L99,20.61a2.67,2.67,0,0,0-1.37-.73,2.94,2.94,0,0,0-.51-.05,2.65,2.65,0,0,0-1.88.78l-4.88,4.88L98,33.17l4.88-4.88A2.66,2.66,0,0,0,102.89,24.53ZM38.51,69.15h2.34V54H38.51Z"/>
                </g>
              </g>
            </svg>
            <p class="support-item-text">
              Registration
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSupport"
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.home-support{
  border-bottom: 1px solid $white;
  .container{
    padding: 42px  0 136px;
    .title{
      text-align: center;
      color: $white;
    }
    .home-support-block{
      max-width: 940px;
      width: 100%;
      margin: 64px auto 0;
      display: flex;
      justify-content: space-between;
      .home-support-block-item{
        max-width: 130px;
        width: 100%;
        svg{
          .cls-1{
            fill: none;
          }
          path{
            fill: $white
          }
        }
        .registration-icon{
          .cls-1, .cls-2 {
            fill: none;
          }
          .cls-2 {
            stroke: $white;
            stroke-linecap:square;
            stroke-miterlimit:10;
            stroke-width: 4.82px;
          }
        }
        .support-item-text{
          margin-top: 8px;
          color: $white;
        }
        &:hover{
          svg{
            transform: scale(1.05, 1.05);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            cursor: pointer;
            transition-duration: 300ms;
            transition-delay: 150ms;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .container{
      padding: 35px 0 40px;
      .title{
        font-size: 32px;
      }
      .home-support-block{
        margin-top: 30px;
        flex-wrap: wrap;
        .home-support-block-item{
          max-width: 50%;
          & > a{
            max-width: 130px;
            width: 100%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>