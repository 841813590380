<template>
  <div id="app">
    <TopBar/>
    <Header/>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/app.scss";

</style>
<script>
import Header from "@/components/Nav/Header";
import TopBar from "@/components/Nav/TopBar";
export default {
  components: {TopBar, Header}
}
</script>