<template>
    <div
        class="sub-nav"
    >
      <nav>
        <ul>
          <li
              v-for="(item, idx) in subNav"
              :key="idx"
          >
            <button @click="open_subcategory(item)">
              <img :src="item.icon_url" alt="logo">
              <p class="sub-menu-text">{{ item.name }}</p>
            </button>
          </li>
        </ul>
      </nav>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SubNav",
  props: ['subNav', 'link'],
  methods: {
    ...mapActions(['subcategoryTitle']),
    open_subcategory(item) {
     this.subcategoryTitle(item.id)
      this.$router.push({
        name: 'categories-category-second',
        params: {
          category: item.parent_id,
          secondCategory: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.sub-nav{
  position: absolute;
  display: block;
  height: auto;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  left: 0;
  width: 100%;
  top: 58px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  background-color: $white;
  ul{
    top: 0;
    left: 0;
    display: flex;
    padding: 0;
    list-style-type: none;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    li{
      margin-left: 44px;
      &:nth-child(1){
        margin-left: 135px;
      }
      button{
        padding: 11px 33px;
        &:hover{
          background-color: rgba(196, 196, 196, .9);
          transition: all .6ms;
        }
        & > p{
          svg{
            width: 54px;
            height: 45px;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    ul{
      flex-wrap: wrap;
      li{
        width: 100%;
        margin: 0 !important;
        text-align: center;
      }
    }
  }

}

</style>
