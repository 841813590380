import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos';
import VueCoreVideoPlayer from 'vue-core-video-player'
import 'aos/dist/aos.css';
AOS.init();
Vue.use(VueCoreVideoPlayer)
Vue.config.productionTip = false

import axios from 'axios'
import VueAxios from 'vue-axios'

const service = axios.create({
  baseURL: 'https://tv-nova.second.envant.me/api'
})

Vue.use(VueAxios, service)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
