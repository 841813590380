import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categoryId: null,
    secondId: null,
    navData: [
      {
        name: 'TV',
        subNav: [
          {
            name: 'UHD TV',
            svg: `${require('@/assets/images/img/nav-icon/1-1.png')}`,
          },
          {
            name : 'HD TV',
            svg:  `${require('@/assets/images/img/nav-icon/1-2.png')}`,
          },
          {
            name: 'TV Mounts',
            svg:  `${require('@/assets/images/img/nav-icon/1-3.png')}`,
          }
        ],
        link: '/tv'
      },
      {
        name: 'Audio',
        subNav: [
          {
            name: 'Earphones + Headphones',
            svg:  `${require('@/assets/images/img/nav-icon/3-1.png')}`
          },
          {
            name: 'Portable Speakers',
            svg: `${require('@/assets/images/img/nav-icon/3-2.png')}`
          },
          {
            name: 'Trolley + Tower Speakers',
            svg: `${require('@/assets/images/img/nav-icon/3-3.png')}`
          },
          {
            name: 'Soundbars',
            svg: `${require('@/assets/images/img/nav-icon/3-4.png')}`
          }
        ],
        link: '/audio'
      },
      {
        name: 'Wearables',
        subNav: [
          {
            name: 'Smart Watch',
            svg:  `${require('@/assets/images/img/nav-icon/2-1.png')}`
          },
          {
            name: 'Smart Watch PRO',
            svg: `${require('@/assets/images/img/nav-icon/2-2.png')}`
          },
          {
            name: 'Smart Braclet',
            svg:  `${require('@/assets/images/img/nav-icon/2-3.png')}`
          }
        ],
        link: '/wearables'
      },
      {
        name: 'Smart Home Electrical',
        subNav: [
          {
            name: 'Smart Outlets + Switches',
            svg:  `${require('@/assets/images/img/nav-icon/4-1.png')}`
          },
          {
            name: 'Smart Lighting',
            svg: `${require('@/assets/images/img/nav-icon/4-2.png')}`
          }
        ],
        link: '/electrical'
      },
      {
        name: 'Smart Home Security',
        subNav: [
          {
            name: 'Smart Alarm Devices',
            svg: `${require('@/assets/images/img/nav-icon/5-1.png')}`
          },
          {
            name: 'Smart Alarm Sensors',
            svg: `${require('@/assets/images/img/nav-icon/5-2.png')}`
          },
          {
            name: 'Smart Alarm Systems',
            svg: `${require('@/assets/images/img/nav-icon/5-3.png')}`
          },
          {
            name: 'Smart Cameras',
            svg: `${require('@/assets/images/img/nav-icon/5-4.png')}`
          },
          {
            name: 'Smart Locks',
            svg: `${require('@/assets/images/img/nav-icon/5-5.png')}`
          }
        ],
        link: '/security'
      }
    ],
    categoryData: [
      {
        title: 'TV',
        text: 'Discover the best picture around at our most affordable prices.',
        src: `${require('@/assets/images/img/tv-home.png')}`,
        link: '/tv'
      },
      {
        title: 'Audio',
        text: 'Expand the limits of audio technology and get the purest sound with our affordable headphones, speakers, and soundbars.',
        src: `${require('@/assets/images/img/audio-home.png')}`,
        link: '/audio'
      },
      {
        title: 'Wearables',
        text: 'Looking for more useful functions that your smartphone can’t provide? Continental smartwatches and fitness trackers help you control your incoming calls and messages, as well as keep track of your heart rate, sleep, activity, and overall fitness level.',
        src: `${require('@/assets/images/img/Wearables-home.png')}`,
        link: '/audio'
      }
    ]

  },
  mutations: {
    SUBCATEGORY_TITLE(state, payload) {
      state.secondId = payload
    },
    CATEGORY_ID(state, payload) {
      state.categoryId = payload
    },
  },
  actions: {
    async subcategoryTitle(context, payload) {
        context.commit('SUBCATEGORY_TITLE', payload)
    },
    async categoryIdRemove(context, payload) {
      context.commit('CATEGORY_ID', payload)
    }
  },
})
