import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue')
  },
  {
    path: '/card',
    name: 'Card',
    component: () => import('../views/Card.vue')
  },
  {
    path: '/categories/:category',
    name: 'categories-category',
    component: () => import('../views/categories/category.vue')
  },
  {
    path: '/categories/:category/:secondCategory',
    name: 'categories-category-second',
    component: () => import('../views/categories/second.vue')
  },
  {
    path: '/products/:product',
    name: 'products-product',
    component: () => import('../views/products/product.vue')
  },
  {
    path: '/tv/:category',
    name: 'Tv',
    component: () => import('../views/Tv.vue')
  },
  {
    path: '/wearables',
    name: 'Wearables',
    component: () => import('../views/Wearables.vue')
  },
  {
    path: '/audio',
    name: 'Audio',
    component: () => import('../views/Audio.vue')
  },
  {
    path: '/electrical',
    name: 'Electrical',
    component: () => import('../views/Electrical.vue')
  },
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/Security.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/return-policy',
    name: 'ReturnPolicy',
    component: () => import('../views/ReturnPolicy.vue')
  },
  {
    path: '/warranty-info',
    name: 'WarrantyInfo',
    component: () => import('../views/WarrantyInfo.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
