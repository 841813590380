<template>
<div class="footer">
  <div class="container">
    <div class="footer-left">
      <div class="footer-left-top">
        <router-link to="/">
          <img src="../../assets/images/ce-logo.png" alt="logo">
        </router-link>
      </div>
      <div class="footer-left-icons">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
          </svg>
        </router-link>
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="footer-right">
      <ul class="footer-right-item">
        <li class="text-item">
          <router-link :to="{path: '/support'}">
            Support
          </router-link>
        </li>
        <li class="nav-button">
          <router-link :to="{path: '/contact'}">
            Contact
          </router-link>
        </li>
        <li class="nav-button">
          <router-link :to="{path: '/return-policy'}">
            Return Policy
          </router-link>
        </li>
        <li class="nav-button">
          <router-link :to="{path: '/warranty-info'}">
            Warranty Info
          </router-link>
        </li>
        <li class="nav-button">
          <router-link :to="{path: '/registration'}">
            Registration
          </router-link>
        </li>
      </ul>
      <ul class="footer-right-item">
        <li class="text-item">
          <router-link to="/">
            Explore By Category
          </router-link>
        </li>
        <li
            class="nav-button"
            v-for="(category, idx) in categories"
            :key="idx"
            @click="getPageId(category.id)"
        >
          <router-link
              :to="{path: `/categories/${category.id}`}"
          >
            {{category.name}}
          </router-link>
        </li>
      </ul>
      <ul class="footer-right-item">
        <li class="text-item">
          <router-link :to="{path: '/about'}">
            About
          </router-link>
        </li>
      </ul>

    </div>
  </div>
</div>
</template>

<script>
import {mapActions} from "vuex/dist/vuex.esm.browser";

export default {
  name: "Footer",
  data (){
    return{
      categories: []
    }
  },
  async created() {
    this.categories = (await this.axios.get('/categories')).data.reverse() || {}
  },
  methods: {
    ...mapActions(['categoryIdRemove']),
    getPageId (id) {
      this.categoryIdRemove(id)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.footer{
  background-color: $blackBg;
  .container{
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    .footer-left{
      max-width: 198px;
      width: 100%;
      margin-left: 40px;
      .footer-left-top{
        margin-top: 55px;
        width: 100%;
        height: 49px;
        & > a{
          width: 100%;
          & > img{
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .footer-left-icons{
        margin-top: 33px;
        display: flex;
        padding-left: 15px;
        & > a{
          width: 28px;
          height: 28px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          &:first-child{
            margin-right: 23px;
          }
          & > svg{
            width: 20px;
            height: 18px;
            fill: $subMenuText;
          }
          &:hover{
            background-image: $blueBg;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 300ms;
            transition-delay: 150ms;
            & > svg{
              fill:  $white;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 300ms;
              transition-delay: 150ms;
            }
          }
        }
      }
    }
    .footer-right{
      max-width: 606px;
      width: 100%;
      margin-right: 103px;
      display: flex;
      justify-content: space-between;
      .footer-right-item{
        margin: 50px 0 59px;
        a{
          color: $white;
        }
        & > .nav-button{
          margin-top: 10px;
          &:nth-child(2){
            margin-top: 15px;
          }
        }
        &:nth-child(1){
          max-width: 89px;
          width: 100%;
        }
        &:nth-child(2){
          max-width: 166px;
          width: 100%;
        }
        &:nth-child(3){
          max-width: 50px;
          width: 100%;
        }
      }

    }

  }
  @media (max-width: 1200px) {
    .container{
      .footer-right{
        margin-right: 50px;
        .footer-right-item{
          margin: 30px 0 30px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .container{
      flex-wrap: wrap;
      .footer-left{
        margin: 0 auto;
        .footer-left-top{
          margin-top: 30px;
        }
        .footer-left-icons{
          margin-top: 15px;
          justify-content: center;
        }
      }
      .footer-right{
        margin: 0 50px;
        max-width: 100%;
        .footer-right-item{
          margin: 30px 0 30px;
        }
      }
    }
  }
  @media (max-width: 576px){
    .container{
      .footer-left-icons {
        padding-left: 0 !important;
      }
      .footer-right{
        margin: 0 5px;
        flex-wrap: wrap;
        .footer-right-item:nth-child(3) {
          max-width: unset;
        }
      }
    }
  }
}
</style>