<template>
  <div
      class="home"
  >
    <HeaderSection/>
    <div class="home-section">
      <div class="container">
        <p class="text-section">
          CE Consumer Electronics offers a wide range of high-quality products for your home or office. With over 20
          years of experience in consumer electronics, we have created devices that are affordable and easy to use.
          Here, we create contemporary electronic products of international level that are stylish
          and available to millions of consumers.
        </p>
        <h2 class="title">View By Category</h2>
      </div>
    </div>
    <HomeProduct
        v-for="(product, idx) in categories"
        :key="idx"
        :category="product"
    />
<!--    <HomeSmart/>-->
    <HomeSupport/>
    <Footer/>
  </div>
</template>

<script>

// import HomeSmart from "@/components/home/HomeSmart";
import HeaderSection from "@/components/HeaderSection";
import HomeProduct from "@/components/HomeProduct";
import HomeSupport from "@/components/home/HomeSupport";
import Footer from "@/components/Nav/Footer";
export default {
  name: 'Home',
  components: {
    Footer,
    HomeSupport,
    HomeProduct,
    HeaderSection,
  },
  mounted() {
    window.scrollTo(0,0)
  },
  data () {
    return{
      categories: []
    }
  },
  async created() {
    this.categories = (await this.axios.get('/categories')).data.reverse()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/_vars.scss";

.home {
  overflow: hidden;
  width: 100%;
  .home-section {
    width: 100%;
    background-color: $white;
    .container {
      padding-top: 79px;
      .text-section {
        max-width: 1130px;
        width: 100%;
        margin: 0 auto;
        color: $subMenuText;
      }

      .title {
        padding: 103px 0 53px;
        color: $titleText;
        text-align: center;
      }
    }
  }
  @media (max-width: 1200px) {
    .home-section{
      .container{
        padding-top: 60px;
        .text-section{
          max-width: 940px;
          margin: 0 auto;
        }
        .title{
          padding: 70px 0 53px;
        }
      }
    }
  }
  @media (max-width: 992px){

  }
}
</style>
