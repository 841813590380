<template>
  <div class="home-product">
    <div class="container">
      <div
          class="home-product-item"
      >
        <div class="home-product-item-img">
          <img :src="category.image_url" alt="category">
        </div>
        <div class="home-product-item-text">
          <h2 class="title-item">
            {{ category.name }}
          </h2>
          <p class="text-item" v-html="category.description"></p>
          <button
              class="btn"
              @click="$router.push({name: 'categories-category', params: {category: category.id}})"
          >
            Show More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeProduct",
  props: ['category']
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.home-product{
  width: 100%;
  .home-product-item{
    margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 630px;
    overflow: hidden;
    padding: 0 120px;
    .home-product-item-img{
      max-width: 430px;
      width: 100%;
      padding: 30px 0;
      & > img{
        width: 100%;
        object-fit: cover;
      }
    }
    .home-product-item-text{
      max-width: 440px;
      width: 100%;
      .title-item{
        color: $white;
      }
      .text-item{
        color: $productItemText;
        margin-top: 24px;
      }
      .btn{
        max-width: 152px;
        margin-top: 32px;
      }
    }
  }
  &:hover{
    transform: scale(1.05, 1.05);
    background-image: $blueBg;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    transition-duration: 300ms;
    transition-delay: 150ms;
  }
  &:nth-child(even){
    .home-product-item{
      flex-flow: row-reverse;
    }
  }
  @media (max-width: 1400px) {
    .home-product-item{
      padding: 0 60px;
      height: 520px;
      .home-product-item-img{
        max-width: 320px;
      }
    }
  }
  @media (max-width: 1200px) {
    .home-product-item{
      padding: 0 20px;
      height: 450px;
      .home-product-item-img{
        max-width: 320px;
      }
    }
  }
  @media (max-width: 992px) {
    .home-product-item{
     padding: 0;
      margin: 0 20px;
      .home-product-item-text {
        max-width: 315px;
      }
    }
  }
  @media (max-width: 576px) {
    .home-product-item{
      padding: 20px 0;
      margin: 0 20px;
      flex-wrap: wrap !important;
      height: auto;
      text-align: center;
      justify-content: center;
      .home-product-item-text {
        max-width: 315px;
      }
    }
  }
}
</style>
